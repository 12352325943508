import React from 'react'
import { Layout, Stack, Main } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Testimonial from '@widgets/Testimonial'
import Sezione3 from '@widgets/Sezione3'
import Sezione4 from '@widgets/Sezione4'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Barra_bassa from '@widgets/Barra_bassa/Barra_bassa'
import Sezione5 from '@widgets/Sezione5'
import Sezione7 from '@widgets/Sezione7'
import Sezione8 from '@widgets/Sezione8'
import Sezione9 from '@widgets/Sezione9'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Sezione10 from '@widgets/Sezione10'
import Faq from '@widgets/Faq/Faq'
import Sezionepage from '@widgets/Sezionepage/Sezionepage'
import Sezionepagetesto from '@widgets/Sezionepage/Sezionepagetesto'


const Page1 = props  => {
  const { pageContext: { services = {} } = {} } = props

  return (
    <Layout {...props}>
<Barra_bassa>
  
</Barra_bassa>

<Seo title='Rimborsi Aerei per Compagnia' lang="it" description="Tutti i rimborsi per compagnie Aeree" />     
      <Divider />
    
      
     <Divider />
      <Stack>
        <Main>
          <Sezionepage />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <Sezionepagetesto />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <ContactForm />
        </Main>
      </Stack> 
        
      
      <Stack>
        <Main>
          {services.mailchimp && (
            <>
              <Divider />
              <NewsletterExpanded simple />
            </>
          )}
        </Main>
      </Stack>
    </Layout>
  )
}

export default Page1

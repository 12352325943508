import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import { Box, Heading, Button } from 'theme-ui'
import { Link } from 'gatsby'



const Sezionepage = () => (
  <>
  <HeroBoxed.Wrapper>


     <div className="boxsezione4"> 
     <div style={{width:"100%", textAlign:"center"}}>
     <Heading variant='h2'>
     Risarcimento e rimborso dalla compagnia aerea</Heading>
</div>
     <div className="boxelementisezionepage">
     <div className=".immagine1sezionepagerisarcimentoincidenteauto">
     </div>     
    
    
    <div style={{textAlign: "center"}}>
    
    Se negli ultimi tre anni hai avuto un volo in ritardo, cancellato o in overbooking, potresti avere diritto a un risarcimento dalla tua compagnia aerea. Eurorimborso è qui per aiutarti a ottenere il compenso che meriti!

Scopri come ottenere il tuo risarcimento dalle principali compagnie aeree con cui collaboriamo.     
    </div>
     </div>
     
     <div style={{width: "100%", textAlign: "center", marginTop: "20px"}}>
  <Button as={Link} to='#ContactForm'>
        AVVIA PRATICA
      </Button>
      </div>




    </div>
  
  </HeroBoxed.Wrapper>
  
  </>
)


const testimonialQuery = graphql`
  query TestimonialQuery {
    file(absolutePath: { regex: "/testimonial.(jpeg|jpg|gif|png)/" }) {
      publicURL
    }
  }
  `
export default Sezionepage

import React from 'react';
import MemphisPattern from '@components/MemphisPattern';
import HeroBoxed from '@widgets/HeroBoxed';
import { Box, Heading, Flex } from 'theme-ui';
import { Link } from 'gatsby';

const Sezionepagetesto = () => (
  <>
    <HeroBoxed.Wrapper>
      <div className="boxsezione4">
        <div style={{ width: "100%", textAlign: "center" }}>
          <Heading variant='h2'>Rimborso Voli Per Compagnie Aeree</Heading>
        </div>
        <br />

        {/* Sezione delle lettere per la navigazione */}
        <div>
          <Heading as="h3">Clicca sulla lettera</Heading>
          <Box sx={{ textAlign: 'center', marginBottom: '20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].map(letter => (
              <Link key={letter} to={`#${letter}`} style={{ margin: '5px' }}>
                {letter}
              </Link>
            ))}
          </Box>
        </div>
      </div>
    </HeroBoxed.Wrapper>

    <HeroBoxed.Wrapper>
      {/* Elenco delle compagnie per ogni lettera */}
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        {/* Sezione per la lettera A */}
        <div id="A" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">A</Heading>
          <ul>
            <li><Link to="/rimborso-air-france-la-guida-completa/">Air France Rimborso</Link></li>
            <li><Link to="/rimborso-albawings/">Albawings Rimborso</Link></li>
            <li><Link to="/rimborso-aegean-airlines/">Aegean Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-austrian-airlines/">Austrian Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-air-senegal/">Air Senegal Rimborso</Link></li>
            <li><Link to="/rimborso-air-arabia-maroc/">Air Arabia Maroc Rimborso</Link></li>
            <li><Link to="/rimborso-air-europa/">Air Europa Rimborso</Link></li>
            <li><Link to="/rimborso-air-malta/">Air Malta Rimborso</Link></li>
            <li><Link to="/rimborso-air-serbia/">Air Serbia Rimborso</Link></li>
            <li><Link to="/rimborso-azul-linhas-aereas-brasileiras/">Azul Linhas Aereas Brasileiras Rimborso</Link></li>
            <li><Link to="/rimborso-american-airlines/">American Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-air-china/">Air China Rimborso</Link></li>
            <li><Link to="/rimborso-aer-lingus/">Aer Lingus Rimborso</Link></li>
            <li><Link to="/rimborso-aeromexico/">Aeromexico Rimborso</Link></li>
            <li><Link to="/rimborso-air-arabia-egypt/">Air Arabia Egypt Rimborso</Link></li>
            <li><Link to="/rimborso-airbaltic/">airBaltic Rimborso</Link></li>
            <li><Link to="/rimborso-air-algerie/">Air Algerie Rimborso</Link></li>
            <li><Link to="/rimborso-aero-republica/">Aero Republica Rimborso</Link></li>
            <li><Link to="/rimborso-avianca/">Avianca Rimborso</Link></li>
            <li><Link to="/rimborso-air-mauritius/">Air Mauritius Rimborso</Link></li>
            <li><Link to="/rimborso-alaska-airlines/">Alaska Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-aerolineas-argentinas/">Aerolineas Argentinas Rimborso</Link></li>
            <li><Link to="/rimborso-air-india/">Air India Rimborso</Link></li>
            <li><Link to="/rimborso-air-nostrum/">Air Nostrum Rimborso</Link></li>
            <li><Link to="/rimborso-air-arabia/">Air Arabia Rimborso</Link></li>
            <li><Link to="/rimborso-air-asia/">Air Asia Rimborso</Link></li>
            <li><Link to="/rimborso-air-dolomiti/">Air Dolomiti Rimborso</Link></li>
            <li><Link to="/rimborso-air-moldova/">Air Moldova Rimborso</Link></li>
            <li><Link to="/rimborso-air-new-zealand/">Air New Zealand Rimborso</Link></li>
            <li><Link to="/rimborso-air-canada/">Air Canada Rimborso</Link></li>
            <li><Link to="/rimborso-air-astana/">Air Astana Rimborso</Link></li>
            <li><Link to="/rimborso-all-nippon-airways/">All Nippon Airways Rimborso</Link></li>
            <li><Link to="/rimborso-asiana-airlines/">Asiana Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-air-asia-indonesia/">Air Asia Indonesia Rimborso</Link></li>
            <li><Link to="/rimborso-albastar/">Albastar Rimborso</Link></li>
            <li><Link to="/rimborso-air-busan/">Air Busan Rimborso</Link></li>
            <li><Link to="/rimborso-air-namibia/">Air Namibia Rimborso</Link></li>
            <li><Link to="/rimborso-auric-air/">Auric Air Rimborso</Link></li>
            <li><Link to="/rimborso-air-austral/">Air Austral Rimborso</Link></li>
            <li><Link to="/rimborso-air-corsica/">Air Corsica Rimborso</Link></li>
            <li><Link to="/rimborso-air-vanuatu/">Air Vanuatu Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera B */}
        <div id="B" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">B</Heading>
          <ul>
            <li><Link to="/rimborso-british-airways/">British Airways Rimborso</Link></li>
            <li><Link to="/rimborso-brussels-airlines/">Brussels Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-belle-air-europe/">Belle Air Europe Rimborso</Link></li>
            <li><Link to="/rimborso-bangkok-airways/">Bangkok Airways Rimborso</Link></li>
            <li><Link to="/rimborso-blue-panorama/">Blue Panorama Rimborso</Link></li>
            <li><Link to="/rimborso-bulgaria-air/">Bulgaria air Rimborso</Link></li>
            <li><Link to="/rimborso-binter-canarias/">Binter Canarias Rimborso</Link></li>
            <li><Link to="/rimborso-blue-air/">Blue Air Rimborso</Link></li>
            <li><Link to="/rimborso-bahamasair/">Bahamasair Rimborso</Link></li>
            <li><Link to="/rimborso-boliviana-de-aviacion/">Boliviana de Aviacion Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera C */}
        <div id="C" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">C</Heading>
          <ul>
            <li><Link to="/rimborso-condor/">Condor Rimborso</Link></li>
            <li><Link to="/rimborso-cyprus-airways/">Cyprus Airways Rimborso</Link></li>
            <li><Link to="/rimborso-citilink-indonesia/">Citilink Indonesia Rimborso</Link></li>
            <li><Link to="/rimborso-cathay-pacific/">Cathay Pacific Rimborso</Link></li>
            <li><Link to="/rimborso-china-southern-airlines/">China Southern Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-cebu-air/">Cebu Air Rimborso</Link></li>
            <li><Link to="/rimborso-cemair/">CemAir Rimborso</Link></li>
            <li><Link to="/rimborso-croatia-airlines/">Croatia Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-copa-airlines/">Copa Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-corendon-airlines/">Corendon Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-china-eastern-airlines/">China Eastern Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-china-airlines/">China Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-citywing/">Citywing Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera D */}
        <div id="D" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">D</Heading>
          <ul>
            <li><Link to="/rimborso-danish-air-transport/">Danish Air Transport Rimborso</Link></li>
            <li><Link to="/rimborso-delta-air-lines/">Delta Air Lines Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera E */}
        <div id="E" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">E</Heading>
          <ul>
            <li><Link to="/rimborso-easyjet/">Easyjet Rimborso</Link></li>
            <li><Link to="/rimborso-eurowings/">Eurowings Rimborso</Link></li>
            <li><Link to="/rimborso-egyptair/">Egyptair Rimborso</Link></li>
            <li><Link to="/rimborso-emirates/">Emirates Rimborso</Link></li>
            <li><Link to="/rimborso-ethiopian-airlines/">Ethiopian Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-etihad-airways/">Etihad Airways Rimborso</Link></li>
            <li><Link to="/rimborso-eva-air/">Eva Air Rimborso</Link></li>
            <li><Link to="/rimborso-ernest-airlines/">Ernest Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-edelweiss-air/">Edelweiss Air Rimborso</Link></li>
            <li><Link to="/rimborso-euroairlines/">Euroairlines Rimborso</Link></li>
            <li><Link to="/rimborso-el-al-israel-airlines/">El Al Israel Airlines Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera F */}
        <div id="F" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">F</Heading>
          <ul>
            <li><Link to="/rimborso-fly-one/">Fly One Rimborso</Link></li>
            <li><Link to="/rimborso-flexflight/">Flexflight Rimborso</Link></li>
            <li><Link to="/rimborso-frontier-airlines/">Frontier Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-flydubai/">Flydubai Rimborso</Link></li>
            <li><Link to="/rimborso-flyadeal/">Flyadeal Rimborso</Link></li>
            <li><Link to="/rimborso-fly-safair/">Fly Safair Rimborso</Link></li>
            <li><Link to="/rimborso-flynas/">Flynas Rimborso</Link></li>
            <li><Link to="/rimborso-freebird-airlines/">Freebird Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-flyfirefly/">Flyfirefly Rimborso</Link></li>
            <li><Link to="/rimborso-fly-play/">Fly Play Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera G */}
        <div id="G" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">G</Heading>
          <ul>
            <li><Link to="/rimborso-gol/">Gol Rimborso</Link></li>
            <li><Link to="/rimborso-gp-aviation/">GP Aviation Rimborso</Link></li>
            <li><Link to="/rimborso-gulf-air/">Gulf Air Rimborso</Link></li>
            <li><Link to="/rimborso-georgian-airways/">Georgian Airways Rimborso</Link></li>
            <li><Link to="/rimborso-garuda-indonesia/">Garuda Indonesia Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera H */}
        <div id="H" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">H</Heading>
          <ul>
            <li><Link to="/rimborso-hahn-air-systems/">Hahn Air Systems Rimborso</Link></li>
            <li><Link to="/rimborso-hahn-air/">Hahn Air Rimborso</Link></li>
            <li><Link to="/rimborso-helvetic-airways/">Helvetic Airways Rimborso</Link></li>
            <li><Link to="/rimborso-hong-kong-express-airways/">Hong Kong Express Airways Rimborso</Link></li>
            <li><Link to="/rimborso-hawaiian-airlines/">Hawaiian Airlines Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera I */}
        <div id="I" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">I</Heading>
          <ul>
            <li><Link to="/rimborso-ita-airways/">ITA Airways Rimborso</Link></li>
            <li><Link to="/rimborso-iberia/">Iberia Rimborso</Link></li>
            <li><Link to="/rimborso-indigo/">IndiGo Rimborso</Link></li>
            <li><Link to="/rimborso-involatus/">Involatus Rimborso</Link></li>
            <li><Link to="/rimborso-iberojet/">iberojet Rimborso</Link></li>
            <li><Link to="/rimborso-intersky-luftfahrt/">Intersky Luftfahrt Rimborso</Link></li>
            <li><Link to="/rimborso-israir-airlines/">Israir Airlines Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera J */}
        <div id="J" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">J</Heading>
          <ul>
            <li><Link to="/rimborso-jet2/">Jet2 Rimborso</Link></li>
            <li><Link to="/rimborso-jetstar/">Jetstar Rimborso</Link></li>
            <li><Link to="/rimborso-jetblue-airways/">JetBlue Airways Rimborso</Link></li>
            <li><Link to="/rimborso-jetstar-asia/">JetStar Asia Rimborso</Link></li>
            <li><Link to="/rimborso-juneyao-airlines/">Juneyao Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-jet-airways/">Jet Airways Rimborso</Link></li>
            <li><Link to="/rimborso-japan-airlines/">Japan Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-japan-transocean-air/">Japan Transocean Air Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera K */}
        <div id="K" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">K</Heading>
          <ul>
            <li><Link to="/rimborso-klm-royal-dutch-airlines/">Klm Royal Dutch Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-kuwait-airways/">Kuwait Airways Rimborso</Link></li>
            <li><Link to="/rimborso-korean-air/">Korean Air Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera L */}
        <div id="L" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">L</Heading>
          <ul>
            <li><Link to="/rimborso-lc-peru/">LC Peru Rimborso</Link></li>
            <li><Link to="/rimborso-lufthansa/">Lufthansa Rimborso</Link></li>
            <li><Link to="/rimborso-latam-airlines/">LATAM Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-lion-airlines/">Lion Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-lot-polish-airlines/">Lot Polish Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-luxair/">Luxair Rimborso</Link></li>
            <li><Link to="/rimborso-lao-airlines/">Lao Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-latam-argentina/">LATAM Argentina Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera M */}
        <div id="M" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">M</Heading>
          <ul>
            <li><Link to="/rimborso-marabu-airlines/">Marabu Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-middle-east-airlines/">Middle East Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-malaysia-airlines/">Malaysia Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-malindoair/">Malindoair Rimborso</Link></li>
            <li><Link to="/rimborso-myanmar-airways-intl/">Myanmar Airways Intl Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera N */}
        <div id="N" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">N</Heading>
          <ul>
            <li><Link to="/rimborso-neos/">Neos Rimborso</Link></li>
            <li><Link to="/rimborso-nouvelair/">Nouvelair Rimborso</Link></li>
            <li><Link to="/rimborso-norwegian-air-international/">Norwegian Air International Rimborso</Link></li>
            <li><Link to="/rimborso-norwegian-air-shuttle/">Norwegian Air Shuttle Rimborso</Link></li>
            <li><Link to="/rimborso-nok-air/">Nok Air Rimborso</Link></li>
            <li><Link to="/rimborso-nesma-airlines/">Nesma Airlines Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera O */}
        <div id="O" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">O</Heading>
          <ul>
            <li><Link to="/rimborso-oman-air/">Oman Air Rimborso</Link></li>
            <li><Link to="/rimborso-olympic-air/">Olympic Air Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera P */}
        <div id="P" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">P</Heading>
          <ul>
            <li><Link to="/rimborso-pegasus-airlines/">Pegasus Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-peach-aviation/">Peach Aviation Rimborso</Link></li>
            <li><Link to="/rimborso-philippine-airlines/">Philippine Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-precision-air/">Precision Air Rimborso</Link></li>
            <li><Link to="/rimborso-porter-airlines/">Porter Airlines Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera Q */}
        <div id="Q" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">Q</Heading>
          <ul>
            <li><Link to="/rimborso-qatar-airways/">Qatar Airways Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera R */}
        <div id="R" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">R</Heading>
          <ul>
            <li><Link to="/rimborso-ryanair/">Ryanair Rimborso</Link></li>
            <li><Link to="/rimborso-royal-air-maroc/">Royal Air Maroc Rimborso</Link></li>
            <li><Link to="/rimborso-royal-jordanian/">Royal Jordanian Rimborso</Link></li>
            <li><Link to="/rimborso-regional-express/">Regional Express Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera S */}
        <div id="S" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">S</Heading>
          <ul>
            <li><Link to="/rimborso-swiss-international-air-lines/">Swiss International Air Lines Rimborso</Link></li>
            <li><Link to="/rimborso-sky-express/">Sky Express Rimborso</Link></li>
            <li><Link to="/rimborso-scandinavian-airlines/">Scandinavian Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-spirit-airlines/">Spirit Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-scoot/">Scoot Rimborso</Link></li>
            <li><Link to="/rimborso-sun-express/">Sun Express Rimborso</Link></li>
            <li><Link to="/rimborso-saudi-arabian-airlines/">Saudi Arabian Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-sky-airline/">Sky Airline Rimborso</Link></li>
            <li><Link to="/rimborso-skyalps-srl/">SkyAlps SRL Rimborso</Link></li>
            <li><Link to="/rimborso-south-african-airlink/">South African Airlink Rimborso</Link></li>
            <li><Link to="/rimborso-sata-international/">SATA International Rimborso</Link></li>
            <li><Link to="/rimborso-south-african-airways/">South African Airways Rimborso</Link></li>
            <li><Link to="/rimborso-spicejet/">Spicejet Rimborso</Link></li>
            <li><Link to="/rimborso-sriwijaya-air/">Sriwijaya Air Rimborso</Link></li>
            <li><Link to="/rimborso-singapore-airlines/">Singapore Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-srilankan-airlines/">Srilankan Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-swiftair/">Swiftair Rimborso</Link></li>
            <li><Link to="/rimborso-spring-airlines/">Spring Airlines Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera T */}
        <div id="T" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">T</Heading>
          <ul>
            <li><Link to="/rimborso-turkish-airlines/">Turkish Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-tunisair/">Tunisair Rimborso</Link></li>
            <li><Link to="/rimborso-tap-portugal/">TAP Portugal Rimborso</Link></li>
            <li><Link to="/rimborso-transavia-france/">Transavia France Rimborso</Link></li>
            <li><Link to="/rimborso-tui-fly/">TUI fly Rimborso</Link></li>
            <li><Link to="/rimborso-transavia-airlines/">Transavia Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-thai-airways/">Thai Airways Rimborso</Link></li>
            <li><Link to="/rimborso-thai-airasia/">Thai AirAsia Rimborso</Link></li>
            <li><Link to="/rimborso-thai-vietjet-air/">Thai Vietjet Air Rimborso</Link></li>
            <li><Link to="/rimborso-tarom/">Tarom Rimborso</Link></li>
            <li><Link to="/rimborso-thai-lion-air/">Thai Lion Air Rimborso</Link></li>
            <li><Link to="/rimborso-trade-air/">Trade Air Rimborso</Link></li>
            <li><Link to="/rimborso-twin-jet/">Twin Jet Rimborso</Link></li>
            <li><Link to="/rimborso-tuifly/">TUIFly Rimborso</Link></li>
            <li><Link to="/rimborso-tvs-smartwings/">TVS Smartwings Rimborso</Link></li>
            <li><Link to="/rimborso-tway-air/">Tway Air Rimborso</Link></li>
            <li><Link to="/rimborso-transportes-aereos-guatemaltecos/">Transportes Aereos Guatemaltecos Rimborso</Link></li>
            <li><Link to="/rimborso-thai-smile-airways/">Thai Smile Airways Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera U */}
        <div id="U" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">U</Heading>
          <ul>
            <li><Link to="/rimborso-united-airlines/">United Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-ural-airlines/">Ural Airlines Rimborso</Link></li>
            <li><Link to="/rimborso-uzbekistan-airways/">Uzbekistan Airways Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera V */}
        <div id="V" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">V</Heading>
          <ul>
            <li><Link to="/rimborso-vueling/">Rimborso Vueling</Link></li>
            <li><Link to="/come-richiedere-il-rimborso-a-volotea/">Rimborso Volotea</Link></li>
            <li><Link to="/rimborso-vietjet/">Vietjet Rimborso</Link></li>
            <li><Link to="/rimborso-volaris/">Volaris Rimborso</Link></li>
            <li><Link to="/rimborso-virgin-australia/">Virgin Australia Rimborso</Link></li>
            <li><Link to="/rimborso-vistara/">Vistara Rimborso</Link></li>
            <li><Link to="/rimborso-virgin-atlantic/">Virgin Atlantic Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera W */}
        <div id="W" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">W</Heading>
          <ul>
            <li><Link to="/rimborso-wizzair/">Rimborso Wizzair</Link></li>
            <li><Link to="/rimborso-westjet/">Westjet Rimborso</Link></li>
            <li><Link to="/rimborso-welcome-air/">Welcome Air Rimborso</Link></li>
            <li><Link to="/rimborso-wizz-air-uk/">Wizz Air UK Rimborso</Link></li>
            <li><Link to="/rimborso-wideroe/">Wideroe Rimborso</Link></li>
          </ul>
        </div>

        {/* Sezione per la lettera X */}
        <div id="X" style={{ width: '100%', maxWidth: '800px' }}>
          <Heading as="h4">X</Heading>
          <ul>
            <li><Link to="/rimborso-xiamen-airlines/">Xiamen Airlines Rimborso</Link></li>
          </ul>

        </div>

       
      </Flex>
    </HeroBoxed.Wrapper>
  </>
);

export default Sezionepagetesto;
